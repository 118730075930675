.page-wrap {
    position: relative;
    /*padding: 0 30px;*/
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    box-sizing: border-box;
}

.page-ct {
    height: 100%;
    padding: 0 30px;
    min-width: 1420px;
    box-sizing: border-box;
}

.data-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    margin: 0 0 10px 0;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-weight: bold;
    color: white;
    background-color: rgb(60, 128, 212);
}

.data-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 14px;
    width: 100%;
    height: 60px;
    border-top: none;
    box-sizing: border-box;
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    cursor: pointer;
}

.data-item:hover {
    background: #4891db;
    color: white;
}

.area-tag {
    color: #2f2f2f;
    font-size: 12px;
    padding: 4px 8px;
    background: #e6fff2;
    margin: 2px 2px;
}

.data-item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    width: 25%;
    font-size: 14px;
    /*display: flex;*/
    /*justify-content: center;*/
}

.data-item-id {
    width: 15%;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.data-item-type {
    width: 10%;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.data-item-area {
    width: 16%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.data-item-date {
    width: 10%;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.data-item-status {
    width: 8%;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.data-item-fun {
    display: flex;
    flex-direction: row;
    width: 8%;
    font-size: 14px;
    justify-content: center;
}

.data-item-fun-check {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.data-item-fun-open {
    padding: 5px 14px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
    text-indent: 2px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.data-ct {
    width: 100%;
    height: calc(100% - 160px);
    min-height: 420px;
    overflow: hidden;
}

.data-form {
    height: calc(100% - 74px);
    overflow-y: auto;
}

.data-form::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;

}

.data-form::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.data-form::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.modal-ct {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1000px;
    height: 85vh;
    transform: translate(-50%, -50%);
    background: #fafafa;
    box-sizing: border-box;
    overflow: hidden;
}

.data-partition {
    width: 100%;
    height: 80px;
    background: transparent;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-partition-ct {
    width: 100%;
    height: 40px;
    background: white;
    display: flex;
    justify-content: center;
}

.data-item-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 20px 0;
    width: 100%;
    height: auto;
    border: none;
    background: #f6f6f6;
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}

.data-item-empty-img {
    width: 70px;
    height: 70px;
}

.data-item-empty-txt {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;

}

.imgs-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.img-wrap {
    width: 200px;
    height: 200px;
    border: 1px solid gray;
}

.img-upload-fun {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /*margin: 10px 0 0;*/
    width: auto;
    height: 30px;
}

.img-upload-btn {
    text-align: center;
    width: 70px;
    height: 30px;
    border: 0;
    border-radius: 6px;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;
    background-color: rgb(60, 128, 212);
}

.img-upload-btn:hover {
    color: #ffffff;
    background-color: rgb(25, 94, 189);
}

.input-file {
    display: none;
}

.img-style {
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.log-select {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.log-line {
    height: 50px;
    display: flex;
    font-size: 14px;
}

.user-header-title {
    font-size: 16px;
    padding-left: 20px;
    font-weight: bold;
    color: #2f2f2f;
}

.user-detail-line {
    height: 50px;
    width: calc(100% - 20px);
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
    background: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.user-detail-title {
    font-size: 14px;
    font-weight: bold;
    padding-right: 10px;
    color: #1a53a8;
    letter-spacing: 2px;
}

.user-line-item {
    display: flex;
    flex-direction: row;
    padding-right: 50px;
    padding-top: 10px;
}

.user-detail-value {
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

.user-button {
    letter-spacing: 2px;
    font-size: 12px;
    text-indent: 2px;
    cursor: pointer;
    margin-left: 10px;
}

.user-info-outer {
    min-width: 1000px;
    height: 100%;
    overflow-y: auto;
}

.user-info-outer::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.user-info-outer::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.user-info-outer::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.user-info-table {
    width: 100%;
    height: auto;
}

.user-info-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: 40px;
}

.user-info-table-header-item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    width: auto;
    height: 100%;
    font-size: 16px;
    color: #1a53a8;
    letter-spacing: 2px;
    cursor: pointer;
}

.user-info-table-header-item-active {
    display: flex;
    align-items: center;
    margin-right: 30px;
    width: auto;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #1a53a8;
    letter-spacing: 2px;
}

.user-info-table-ct {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 5px 0;
    width: auto;
    height: 60px;
    color: black;
    border-bottom: 1px solid rgba(0,0,0,.06);
    box-sizing: border-box;
}

.user-info-table-ct-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    color: white;
    background-color: rgb(60, 128, 212);
}

.user-info-table-ct-item {
    display: flex;
    align-items: flex-start;
    width: auto;
    height: auto;
    font-size: 14px;
    line-height: 30px;
    /*display: -webkit-box;*/
    /*-webkit-box-orient: vertical;*/
    /*-webkit-line-clamp: 1;*/
    /*overflow: hidden;*/
}

.app-orderId {
    margin-right: 5%;
    width: 15%;
    word-break: break-all;
}

.app-userId {
    margin-right: 5%;
    width: 15%;
    word-break: break-all;
}

.app-date {
    width: 10%;
}

.app-time {
    width: 10%;
}

.app-title {
    margin-right: 5%;
    width: 15%;
}

.app-name {
    width: 10%;
}

.app-phone {
    width: 10%;
}

.user-subs-status-title {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #666973;
    height: 60px;
    display: flex;
    align-items: center;
}

.user-subs-ct {
    padding: 10px 20px;
    background: #dbe8f6;
    width: calc(100% - 280px);
    min-width: 800px;
}

.subscript-ct-line {
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.subscript-ct-line-tag {
    font-weight: bold;
    color: #3f3f3f;
    letter-spacing: 2px;
}

.subscript-ct-line-ct {
    margin-left: 10px;
    background: white;
    color: #4483ce;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 2px;
    padding: 3px 14px;
}

.user-subs-status-tag {
    margin-left: 10px;
    font-size: 12px;
    color: #6c84a6;
}

.user-info-big-title {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #5c5c5c;
}

.id-index {
    width: 100px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.currency-item {
    width: 150px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.id-item {
    width: 200px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bill-line {
    height: 50px;
    display: flex;
    font-size: 14px;
}

.bill-summary-title {
    height: 100%;
    width: 130px;
    background: #2f7ad7;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 4px;
    text-indent: 4px;
}

.bill-summary-ct {
    height: 100%;
    width: calc(100% - 130px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.bill-summary-item {
    margin: 0 1%;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.bill-summary-item-title {
    margin-right: 8px;
    letter-spacing: 1px;
    text-indent: 1px;
}

.bill-summary-item-ct {
    font-size: 18px;
    color: red;
}

.log-line-item {
    height: 50px;
    border: 2px solid rgba(125, 163, 205, 0.6);
    display: flex;
    font-size: 14px;
    cursor: pointer;
}

.log-line-item:hover {
    height: 54px;
    background: rgb(72, 136, 215);
    color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    border: 0;
}

.vip-back {
    height: 100%;
    display: flex;
    align-items: center;
    color: #0056d6;
    cursor: pointer;
    letter-spacing: 1px;
}

.add-host {
    height: 40px;
    border: 0;
    padding: 5px 16px;
    background: #dfffdf;
    font-weight: bold;
    color: #575757;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.host-input:focus {
    height: 30px;
    margin: 0;
    /*padding: 0;*/
    outline: none;
}

.host-input {
    height: 30px;
    margin: 0;
    padding: 0 8px;
    border: 1px solid #acacac;
    box-sizing: border-box;
}

.host-button {
    border: 0;
    cursor: pointer;
    color: white;
    padding: 5px 8px;
    letter-spacing: 1px;
    font-size: 12px;
    text-indent: 1px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.vip-delay-win {
    width: 340px;
    height: 200px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.ptc-adj-win {
    width: 400px;
    height: 250px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.user-points-win {
    width: 430px;
    height: 260px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.vip-delay-win-ct {
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.point-update-win-ct {
    height: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.log-line-op-tp {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-line-op-item {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-line-op-notice {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-line-op-date {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-line-item-tp {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

.host-account-item {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-phone-item {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-last-item {
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-reg-item {
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-level-item {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coin-account-title {
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
    color: #1167d2;
}

.coin-account-num {
    font-size: 14px;
    color: red;
    font-weight: bold;
    letter-spacing: 1px;
}

.host-level-op {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-account-item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-line-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-add-item {
    cursor: pointer;
    width: 50px;
    height: 100%;
    font-size: 20px;
    background: #787878;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-level-item {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-subs-item {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-expire-item {
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-op-item {
    width: calc(25% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip-table {
    height: 94%;
    overflow-y: auto;
}

.vip-table::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.vip-table::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.vip-table::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.statistics-user {
    width: calc(100% - 150px);
    min-height: 300px;
    background: transparent;
    margin: 0 50px 40px 50px;
    padding: 50px 30px 40px 30px;
    border: 1px solid rgba(145, 161, 184, 0.6);
}

.statistics-item-part {
    width: 100%;
    padding: 0;
}

.num-tag-item {
    margin-right: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #5c5c5c;
}

.user-table {
    height: 100%;
    overflow-y: auto;
}

.user-table::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;

}

.user-table::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.user-table::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.host-table {
    height: 96%;
    overflow-y: auto;
}

.host-table::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;

}

.host-table::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.host-table::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.user-id-item {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-phone-item {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-coin-item {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-check-item {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistics-title {
    width: 100%;
    height: 80px;
    font-size: 22px;
    margin-left: 50px;
    color: #466bac;
    font-weight: bold;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
}
