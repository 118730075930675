/*
// 基础框架 css
*/
.layout-wrap {
    /*font-family: 'Times New Roman',Times, '宋体', serif;*/
    font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}

.layout-header {
    height: 60px;
    width: 100%;
    background: #f1f7ff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    z-index: 999;
}

.layout-ct {
    position: relative;
    display: flex;
    /*margin-top: 60px;*/
    width: 100%;
    height: 100vh;
    min-width: 1200px;
    background: #f5f5f5;
}

.layout-menu {
    height: 100vh;
    width: 260px;
    overflow-x: hidden;
    background: #ffffff;
    display: flex;
    transition-property: width;
    -moz-transition-property: width; /* Firefox 4 */
    -webkit-transition-property: width; /* Safari and Chrome */
    -o-transition-property: width; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.layout-menu-ct {
    width: calc(100% - 18px);
    height: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: hidden;
}

.layout-menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 20px;
    height: 50px;
    font-size: 14px;
    letter-spacing: 4px;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #d0d0d0;
    color: #4c4c4c;
    font-weight: bold;
    min-height: 50px;
    box-sizing: border-box;
}

.logout-item {
    text-indent: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 51px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    background-color: #1050dc;
}

.layout-menu-items-wrap {
    background: transparent;
    width: 100%;
    /*margin-top: 40px;*/
    overflow-y: auto;
    overflow-x: hidden;
    /*min-height: 500px;*/
}

.change-pass-line {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    /*background: #5c5c5c;*/
}

.change-pass {
    padding-left: 20px;
    font-size: 12px;
    letter-spacing: 2px;
    color: #0075f3;
    cursor: pointer;
}

.layout-menu-items-wrap::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.layout-menu-items-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.layout-menu-items-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.layout-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    width: calc(100% - 20px);
    height: 46px;
    font-size: 14px;
    letter-spacing: 2px;
    background: transparent;
    color: #4c4c4c;
    cursor: pointer;
}

.layout-menu-item:hover {
    background: rgba(165, 206, 255, 0.1);
    font-weight: bold;
}

.layout-menu-sub-item {
    background: rgba(207, 216, 224, 0.8);
    transition-property: opacity;
    -moz-transition-property: opacity; /* Firefox 4 */
    -webkit-transition-property: opacity; /* Safari and Chrome */
    -o-transition-property: opacity; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.layout-menu-item-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    height: 46px;
    font-size: 14px;
    letter-spacing: 2px;
    width: calc(100% - 20px);
    color: #4c4c4c;
    cursor: pointer;
    transition-property: opacity;
    -moz-transition-property: opacity; /* Firefox 4 */
    -webkit-transition-property: opacity; /* Safari and Chrome */
    -o-transition-property: opacity; /* Opera */
    transition-delay: 150ms;
    -moz-transition-delay: 150ms;
    -webkit-transition-delay: 150ms;
    -o-transition-delay: 150ms;
}

.layout-menu-item-sub:hover {
    background: rgba(97, 184, 251, 0.1);
    font-weight: bold;
}

.layout-menu-btn-wrap {
    width: 18px;
    height: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.layout-menu-btn {
    display: flex;
    align-items: center;
    font-size: 20px;
    height: 100px;
    width: 100%;
    color: #868686;
    background: #e8e8e8;
    border-bottom: 20px solid #f5f5f5;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f5f5f5;
    cursor: pointer;
}

.layout-container {
    padding: 20px 20px;
    width: calc(100% - 260px);
    height: 100vh;
    background-color: #f5f5f5;
    overflow-y: auto;
    box-sizing: border-box;
    transition-property: width;
    -moz-transition-property: width; /* Firefox 4 */
    -webkit-transition-property: width; /* Safari and Chrome */
    -o-transition-property: width; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.change-pwd {

}

/*
// login css
*/
.login-plugin-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-width: 1200px;
    min-height: 670px;
    background: #efefef;
}

.login-plugin {
    display: flex;
    width: 500px;
    height: 540px;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.login-plugin-ct-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
}

.login-plugin-ct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 76%;
    height: 84%;
    background: transparent;
}

.login-plugin-ct-title {
    display: flex;
    width: 100%;
    height: 50px;
    background: transparent;
}

.title-name {
    width: 50%;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #5c5c5c;
    background: transparent;
}

.login-plugin-ct-input-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    background: transparent;
}

.login-input-style {
    margin: 0;
    padding-left: 10px;
    width: calc(100% - 14px);
    height: 40px;
    font-size: 16px;
    border: 1px solid #d7d7d7;
}

.login-input-style:focus {
    margin: 0;
    padding-left: 10px;
    border: 1px solid #8aaed7;
    outline: none;
}

.login-input-style::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    letter-spacing: 1px;
    color: #9f9f9f;
}

.login-input-style::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    letter-spacing: 1px;
    color: #9f9f9f;
}

.login-input-style:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    letter-spacing: 1px;
    color: #9f9f9f;
}

.login-input-style:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    letter-spacing: 1px;
    color: #9f9f9f;
}

.send-code-get {
    position: absolute;
    /*top: 0;*/
    /*right: 25%;*/
    background-color: transparent;
    border: none;
    font-size: 12px;
    color: #3399ea;
    line-height: 24px;
    letter-spacing: 1px;
    transform: translate(280px, -114px);
    cursor: pointer;
}

.save-button {
    background: #00a1d6;
}

.save-button:hover {
    background: #338fd7;
}

.save-button-none {
    pointer-events: none;
    color: #6e6e6e;
}

.eye-style {
    position: absolute;
    font-size: 18px;
    transform: translateX(344px);
    cursor: pointer;
}

.login-plugin-ct-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 42px;
    font-size: 18px;
    letter-spacing: 4px;
    text-indent: 4px;
    color: white;
    background: #3c80d4;
    cursor: pointer;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.ant-layout-content {
    width: 100%;
    height: 100%;
}