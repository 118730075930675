/*
// modal css
*/
.modal-bg {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
}

.page-inner {
    position: relative;
    padding: 20px 30px 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 40px;
    background: #3c80d4;
    font-size: 16px;
    font-weight: bolder;
    color: white;
    box-sizing: border-box;
}

.page-close {
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.page-close:hover {
    transform: rotate(180deg);
}

.policy-edit-part-wrap {
    width: 100%;
    height: calc(100% - 70px);
    box-sizing: border-box;
    overflow-y: auto;
}

.policy-edit-part-wrap::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.policy-edit-part-wrap::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.policy-edit-part-wrap::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.policy-edit-part {
    width: 100%;
    overflow: auto;
}

.outcome-edit-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 300px;
    height: 100%;
    background: transparent;
    line-height: 50px;
}

.required-icon {
    width: 20px;
    /*height: 20px;*/
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    /*line-height: 30px;*/
    font-size: 20px;
    color: #f00;
}

.edit-item-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    min-width: 90px;
    font-weight: bold;
}

.flex-start {
    justify-content: flex-start
}

.outcome-item-title {
    margin-right: 10px;
    /*font-weight: bold;*/
}

.outcome-item-title-tip {
    margin-left: 4px;
    font-size: 12px;
    color: #4c4c4c;
}

.edit-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    font-size: 14px;
}

.outcome-edit-line {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    width: 100%;
    /*background: #d6e5d6;*/
    font-size: 14px;
}

.data-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    width: 100%;
    height: 50px;
    background: transparent;
}

.data-selector-left {
    display: flex;
    flex-direction: row;
    width: auto;
}

.data-selector-right {
    display: flex;
    flex-direction: row;
    width: auto;
}

.data-selector-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 30px;
    width: auto;
}

.search-txt {
    margin: 0 8px 0 0;
    font-size: 12px;
    font-weight: bolder;
}

.item-back {
    height: 50px;
    width: 50px;
    background: #1050dc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.item-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    width: calc(100% - 50px);
    height: 50px;
    background: #eeeeee;
    font-size: 14px;
    letter-spacing: 2px;
    color: black;
    box-sizing: border-box;
}

.item-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 80px;
    height: 60%;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
}

.big-data-para-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 10px;
}

.big-data-para {
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #3f3f3f;
}

.big-data-para-add {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    color: #4c4c4c;
    cursor: pointer;
}

.big-data-para-add-icon {
    font-size: 14px;
    margin-right: 5px;
}

.modal-header-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100px;
    padding: 22px 30px;
    background: url("../../assets/img/modal_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.modal-header-title {
    font-size: 16px;
    font-weight: bolder;
    font-family: MicrosoftYaHei;
    letter-spacing: 1px;
    color: #ffffff;
}

.modal-header-subtitle {
    margin-top: 8px;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 1px;
    font-family: MicrosoftYaHei;
}

.modal-close-wrap {
    z-index: 10;
    position: absolute;
    top: 20px;
    right: 30px;
    /*width: 14px;*/
    /*height: 14px;*/
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    color: rgb(255, 255, 255);
}

.modal-close-wrap:hover {
    transform: rotate(180deg);
}

.change-pwd-modal-ct {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    /*margin: 0 auto;*/
    /*padding: 20px 30px;*/
    width: 600px;
    height: 460px;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
    border-radius: 5px;
    box-sizing: border-box;
}

.modal-ct-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto 0;
    width: 100%;
    height: calc(100% - 120px);
}

.send-code-msg {
    margin-bottom: 8px;
    width: 60%;
    height: 30px;
    font-size: 14px;
    color: #4d4d4d;
    letter-spacing: 0;
    line-height: 22px;
}

.send-code-tip {
    width: 60%;
    height: 30px;
    font-size: 14px;
    color: #ccc;
    letter-spacing: 0;
    line-height: 22px;
}

.send-code-ct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 0;
    width: 60%;
    height: 50px;
}

.send-code-ct-text {
    width: 30%;
    font-size: 14px;
    color: #4d4d4d;
    letter-spacing: 0;
    line-height: 22px;
    vertical-align: middle;
}

/*.send-code-get {*/
/*    position: absolute;*/
/*    !*top: 0;*!*/
/*    !*right: 25%;*!*/
/*    background-color: transparent;*/
/*    border: none;*/
/*    font-size: 12px;*/
/*    color: #3399ea;*/
/*    line-height: 24px;*/
/*    letter-spacing: 1px;*/
/*    transform: translate(280px, 0);*/
/*    cursor: pointer;*/
/*}*/

.send-code-next-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    width: 100px;
    height: 38px;
    border-radius: 4px;
    border: 0;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.pwd-eye-out {
    position: absolute;;
    transform: translate(320px, 0);
    font-size: 18px;
    cursor: pointer;
}

.input-style {
    padding: 3px 10px;
    width: 400px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.input-style::placeholder {
    font-size: 14px;
    letter-spacing: 1px;
}

.input-style:hover {
    border: 1px solid #8391a5;
}

.input-style:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.pass-update-win {
    height: 240px;
    width: 420px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.pass-update-ct {
    width: 100%;
    height: 200px;
}

.pass-update-line {
    height: 54px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.save-button {*/
/*    background: #00a1d6;*/
/*}*/

/*.save-button:hover {*/
/*    background: #338fd7;*/
/*}*/

/*.save-button-none {*/
/*    pointer-events: none;*/
/*    background: #6e6e6e;*/
/*}*/