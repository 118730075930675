.message-win {
    padding: 8px 14px;
    width: auto;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 2px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    color: #5e5e5e;
}

.check-box {
    margin-right: 10px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lunch-win-out {
    position: absolute;
    left: 0;
    top: 0;
    /*bottom: 0;*/
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(159, 159, 159, 0.5);
}

.search-tool {
    width: 260px;
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.auth-tool {
    width: 80%;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
    margin-top: 20px;
}

.auth-title {
    font-size: 16px;
    font-weight: bold;
}

.search-input {
    height: 30px;
    margin: 0;
    padding: 0 8px;
    border: 2px solid #7da3cd;
    box-sizing: border-box;
}

.search-input-unactivated {
    height: 30px;
    margin: 0;
    padding: 0 8px;
    border: 2px solid #7da3cd;
    box-sizing: border-box;
    opacity: 0.7;
}

.search-input:focus {
    height: 30px;
    margin: 0;
    padding: 0 8px;
    /*border: 2px solid #7da3cd;*/
    /*box-sizing: border-box;*/
    outline: none;
}

.search-input-unactivated:focus {
    height: 30px;
    margin: 0;
    padding: 0 8px;
    /*border: 2px solid #7da3cd;*/
    /*box-sizing: border-box;*/
    outline: none;
}

.vip-win-des {
    font-size: 14px;
    font-weight: bold;
    color: #2f2f2f;
}

.pic-win {
    width: 600px;
    height: 360px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.pic-layout {
    width: 100%;
    height: 320px;
    background: #2f2f2f;
    display: flex;
}

.pic-switch {
    height: 100%;
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
}

.pic-ct {
    height: 100%;
    width: 84%;
    background: #b2c4c7;
    cursor: pointer;
}

.close-pic {
    height: 40px;
    width: 100%;
    background: #3c80d4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.search-bt {
    height: 30px;
    width: 60px;
    cursor: pointer;
    background: #3c80d4;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    color: white;
    border: 0;
    padding: 0;
}

.add-bt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 40px;
    padding: 0;
    height: 30px;
    width: 80px;
    cursor: pointer;
    background: #09af6d;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    color: white;
    border: 0;
}

.auth-win {
    width: 500px;
    min-height: 200px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.auth-close {
    width: 100%;
    height: 40px;
    background: #3b71b3;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.num-bt-outer {
    max-width: 74%;
    min-width: 100px;
    height: 100%;
    background: transparent;
    display: flex;
    /*justify-content: space-evenly;*/
    /*align-items: center;*/
}

.check-move-bt {
    font-size: 16px;
    height: 30px;
    width: 30px;
    background: #3c80d4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px 4px 5px 4px;
}

.num-input-bt-outer {
    width: 20%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.num-input-win {
    background: transparent;
    font-size: 14px;
    border: 2px solid #9fccff;
    height: calc(30px - 4px);
    width: calc(50px - 4px);
    margin: 0 5px 0 0;
    padding: 0 0 0 10px;
}

.num-input-win-go {
    height: 30px;
    border: 0;
    width: 60px;
    cursor: pointer;
    margin: 0;
    letter-spacing: 4px;
    text-indent: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #3c80d4;
}

.num-input-win:focus {
    outline: none;
}

.filter-area {
    z-index: 3;
    display: flex;
    align-items: center;
    /*margin-top: 20px;*/
    /*margin-bottom: 10px;*/
    width: auto;
    height: 30px;
    background: transparent;
}

.filter-area-unactivated {
    z-index: 3;
    display: flex;
    align-items: center;
    width: auto;
    height: 30px;
    background: transparent;
    opacity: 0.7;
}

.filter-area-geo-title {
    background: transparent;
    font-size: 14px;
    letter-spacing: 2px;
}

.filter-area-geo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    padding: 0 10px;
    font-size: 14px;
    letter-spacing: 2px;
    color: white;
    background: #4781c9;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    cursor: pointer;
}

@keyframes filter-geo-amin {
    0% {
        transform: translate(0, 10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.geo-province {
    display: flex;
    align-items: center;
    padding: 6px 4px 6px 10px;
    height: 20px;
}

.geo-province:hover {
    background: #5e7ba7;
}

.city-tag {
    display: flex;
    align-items: center;
    padding: 6px 10px 6px 10px;
    height: 20px;
}

.city-tag:hover {
    background: #6e8cb8;
}

.geo-cities {
    z-index: 1;
    position: absolute;
    margin-left: 160px;
    padding: 35px 0 0 0;
    top: 0;
    letter-spacing: 2px;
    background: transparent;
}

.city-ct {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding: 10px 0 10px 0;
    min-width: 180px;
    height: 350px;
    color: #ffffff;
    background: rgba(53, 127, 213, 0.9);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.city-ct::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.city-ct::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.city-ct::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
    background-color: transparent;
}

.geo-outer {
    position: absolute;
    padding: 35px 0 0 0;
    /*margin-left: -14px;*/
    top: 0;
    left: 0;
    /*height: 500px;*/
    letter-spacing: 2px;
    background: transparent;
    animation: filter-geo-amin 200ms 1 linear;
    -webkit-animation: filter-geo-amin 200ms 1 linear;
}

.geo-ct {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 0;
    min-width: 180px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    height: 350px;
    color: #ffffff;
    background: rgba(63, 131, 215, 0.8);
    overflow-y: auto;
}

.geo-ct::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.geo-ct::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.geo-ct::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
    background-color: transparent;
}


