.reject-win {
    width: 420px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.reject-ct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
}

.reject-ct-item {
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.commit-win {
    width: 420px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.commit-ct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);
}

.commit-ct-item {
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}